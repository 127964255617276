

.e-schedule-event-tooltip .tooltip-wrap {
  display: flex;
  align-items: center;
}

.e-schedule-event-tooltip .tooltip-wrap .content-area {
  padding-left: 6px;
  font-size: 14px;
  margin: 0px;
}

.e-schedule-event-tooltip .tooltip-wrap .project_name {
  font-weight: 500;
  font-size: 16px;
}

.e-schedule-event-tooltip .tooltip-wrap .status {
  font-size: 12px;
}

.e-schedule-event-tooltip .tooltip-wrap .comments {
  font-size: 12px;
}

.drag-sample-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-outline {
  margin-top: 100;
  padding-top: 20;
}

/* The css for the event dialog popup is in the index.css file */

.e-schedule .e-month-view .e-appointment .e-appointment-details {
  justify-content: center;
}

.schedule-control-section {
  margin-top: 15px;
}

.schedule-container {
  margin-left: 10px;
  margin-right: 10px;
}

.schedule-title-container {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px; /* Add left padding */
  padding-right: 10px; /* Add right padding */
}

.unscheduled-title-container {
  padding-right: 20px; /* Add right padding */
}

.unscheduled-title-box {
    padding-bottom: 10px;
  }



.treeview-container {
  min-height: 100px;
  min-width: 100px;
  max-height: 80vh;
  overflow-y: auto;
  margin-right: 15px;
  border: 1px solid #ccc; /* Optional: adds a border around the treeview */
}

.treeview-external-drag #waiting {
  height: 100%;
  overflow: auto;
  padding: 0;
  /* background-color: #3f51b5; 4caf50*/
  background-color: #4caf50;
  color: #fff;
}

.treeview-external-drag #waitdetails {
  width: 95%;
  float: left;
  height: 95%;
  padding: 0;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .template-wrap {
  padding: 3px 0px;
}

.title-text {
  font-size: 24px;
  margin: 0px;
  font-weight: bold;
  text-align: center;
}

.treeview-external-drag #waitlist {
  width: 100%;
  height: 25px;
  font-weight: bold;
  font-family: "Segoe UI";
  font-size: 16px;
  padding: 5px 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeview-external-drag #waitcategory {
  height: 25px;
  font-family: "Segoe UI";
  font-size: 14px;
  opacity: 0.6;
  padding-left: 10px;
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeview-external-drag #waitrequired {
  height: 25px;
  font-family: "Segoe UI";
  font-size: 14px;
  opacity: 0.6;
  padding-left: 10px;
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeview-external-drag #reqcalendar {
  height: 325px;
  padding-left: 30px;
  align-content: center;
  justify-content: center;
}

.treeview-external-drag .e-list-text,
.treeview-external-drag.e-rtl .e-list-text,
.e-bigger .treeview-external-drag .e-list-text,
.e-bigger .treeview-external-drag.e-rtl .e-list-text {
  border: 0.5px solid #e1e7ec;
  height: auto;
  /* overflow: scroll; */
  line-height: 15px;
  padding: 0 5px;
  width: 320px;
}

.treeview-external-drag .e-list-parent,
.treeview-external-drag.e-rtl .e-list-parent,
.e-bigger .treeview-external-drag .e-list-parent,
.e-bigger .treeview-external-drag.e-rtl .e-list-parent {
  height: 100%;
  padding: 0 2px;
}

.treeview-external-drag .e-list-item,
.e-bigger .treeview-external-drag .e-list-item {
  height: 100%;
  padding: 0 0 5px 0;
}

.treeview-external-drag .e-fullrow,
.e-bigger .treeview-external-drag .e-fullrow {
  height: 55px;
}

.treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.treeview-external-drag .e-list-item.e-active > .e-fullrow,
.treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important;
}
.fluent2-highcontrast
  .treeview-external-drag
  .e-list-item.e-hover
  > .e-text-content
  .e-list-text {
  color: #fff;
}
.treeview-external-drag .e-text-content,
.e-bigger .treeview-external-drag .e-text-content,
.treeview-external-drag.e-rtl .e-text-content,
.e-bigger .treeview-external-drag.e-rtl .e-text-content {
  padding: 0;
  background-color: inherit;
}

.e-drag-item.e-treeview.treeview-external-drag,
.e-bigger .e-drag-item.e-treeview.treeview-external-drag {
  padding: 0 !important;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
  width: 160px;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-category {
  padding-left: 30px;
}

.e-schedule.e-rtl.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-category {
  padding-right: 30px;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-child-node
  .specialist-category,
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
  padding: 5px;
}

.e-schedule.schedule-drag-drop
  .e-resource-cells.e-parent-node
  .specialist-name {
  padding: 0 10px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-name {
  font-size: 13px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-designation {
  font-size: 10px;
}

.e-schedule.schedule-drag-drop .specialist-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 45px;
}

.e-device-hover {
  background-color: #e0e0e0 !important;
}

@media (max-width: 550px) {
  .drag-sample-wrapper {
    display: block;
  }
  .schedule-container {
    padding-bottom: 10px;
  }
  .treeview-external-drag.e-treeview,
  .e-bigger .treeview-external-drag.e-treeview {
    width: 250px;
  }
  .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
    position: relative !important;
  }
}
.e-disble-not-allowed {
  cursor: unset !important;
}

.e-drag-item.treeview-external-drag .e-icon-expandable {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.e-schedule-dialog .e-all-day-container{

  display: none;

}

.e-schedule-dialog .e-resources-row{

  display: none;

}

/* .e-schedule-dialog .e-description-container{

  display: none;

} */

.e-schedule-dialog .e-recurrenceeditor{

  display: none;

}

.e-schedule-dialog .e-subject{

  pointer-events: none;

}

.e-schedule-dialog .e-location{

  pointer-events: none;

}